.CookieConsent {
	background-color: #0c0e12 !important;
}

#rcc-confirm-button {
	background-color: #661ae6 !important;
	color: white !important;

	&:hover {
		background-color: #5114b9 !important;
	}
}
