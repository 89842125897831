.h-100 {
	height: 28rem;
}

.h-104 {
	height: 32rem;
}
.h-106 {
	height: 34rem;
}
.h-108 {
	height: 36rem;
}
.h-110 {
	height: 38rem;
}
.h-112 {
	height: 40rem;
}
.h-114 {
	height: 42rem;
}
.h-116 {
	height: 44rem;
}
.h-118 {
	height: 46rem;
}

.w-100 {
	width: 30rem;
}
@media (min-width: 800px) {
	.w-100 {
		width: 20rem;
	}
}
@media (min-width: 1921px) {
	.w-100 {
		width: 40rem;
	}
}

.w-110 {
	width: 40rem;
}
@media (min-width: 800px) {
	.w-110 {
		width: 45rem;
	}
}
@media (min-width: 1921px) {
	.w-110 {
		width: 50rem;
	}
}

.z-60 {
	z-index: 60;
}
.z-61 {
	z-index: 61;
}

.z-100 {
	z-index: 100;
}

.z-101 {
	z-index: 101;
}

.z-1000 {
	z-index: 1000;
}

.card-sizing {
	padding-bottom: 4rem;
}

@media (min-width: 641px) {
	.card-sizing {
		padding-bottom: 4rem;
	}
}
@media (min-width: 801px) {
	.card-sizing {
		padding-bottom: 4rem;
	}
}

@media (min-width: 2561px) {
	.card-sizing {
		padding-bottom: 5rem;
	}
}

.notifications {
	width: 80vw;
	max-height: 60rem;
}

@media (min-width: 641px) {
	.notifications {
		width: 60vw;
	}
}

@media (min-width: 1025px) {
	.notifications {
		width: 50vw;
	}
}

@media (min-width: 1281px) {
	.notifications {
		width: 30vw;
	}
}

@media (min-width: 1921px) {
	.notifications {
		width: 60rem;
	}
}

.left-bar {
	width: 28%;
}

@media (min-width: 1025px) {
	.left-bar {
		width: 15%;
	}
}

@media (min-width: 1921px) {
	.left-bar {
		width: 12%;
	}
}

.carousel {
	width: 100%;
	aspect-ratio: 16/9;
}

.hero {
	height: fit-content;
}

@media (min-width: 768px) {
	.hero {
		height: 50rem;
	}
}

@media (min-width: 1025px) {
	.hero {
		height: 30rem;
	}
}

@media (min-width: 1921px) {
	.hero {
		height: 40rem;
	}
}

@media (min-width: 2561px) {
	.hero {
		height: 45rem;
	}
}

.conversation-size {
	height: 47rem;
}

.hero-card {
	width: 100%;
}

@media (min-width: 768px) {
	.hero-card {
		width: 100%;
	}
}

@media (min-width: 1025px) {
	.hero-card {
		width: 80rem;
	}
}

@media (min-width: 1921px) {
	.hero-card {
		width: 100rem;
	}
}

@media (min-width: 2561px) {
	.hero-card {
		width: 120rem;
	}
}
