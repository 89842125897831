.lds-dual-ring {
	display: inline-block;
	width: 100%;
	max-width: 5rem;
	aspect-ratio: 1/1;
}
.lds-dual-ring:after {
	content: ' ';
	display: block;
	width: calc(100% - 2rem);
	aspect-ratio: 1/1;
	margin: 8px;
	border-radius: 50%;
	border: 6px solid #fff;
	border-color: #fff transparent #fff transparent;
	animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
