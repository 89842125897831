.truncate-3 {
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	inline-size: 100%;
}

.truncate-2 {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.multi-line-truncate {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	inline-size: 35rem;
}

@media (max-width: 639px) {
	.multi-line-truncate {
		-webkit-line-clamp: 1;
	}
}

@media (min-width: 640px) {
	.multi-line-truncate {
		inline-size: 30rem;
	}
}

@media (min-width: 770px) {
	.multi-line-truncate {
		inline-size: 21rem;
	}
}

@media (min-width: 1024px) {
	.multi-line-truncate {
		inline-size: 25rem;
	}
}

@media (min-width: 1536px) {
	.multi-line-truncate {
		inline-size: 22rem;
	}
}
@media (min-width: 1921px) {
	.multi-line-truncate {
		inline-size: 26rem;
	}
}
@media (min-width: 2561px) {
	.multi-line-truncate {
		inline-size: 36rem;
	}
}
@media (min-width: 3200px) {
	.multi-line-truncate {
		inline-size: 45rem;
	}
}

.build-title-truncate {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.conversations-preview-text {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	inline-size: 35rem;
}

@media (max-width: 639px) {
	.conversations-preview-text {
		-webkit-line-clamp: 1;
	}
}

@media (min-width: 640px) {
	.conversations-preview-text {
		inline-size: 30rem;
	}
}

@media (min-width: 770px) {
	.conversations-preview-text {
		inline-size: 21rem;
	}
}

@media (min-width: 1024px) {
	.conversations-preview-text {
		inline-size: 25rem;
	}
}

@media (min-width: 1536px) {
	.conversations-preview-text {
		inline-size: 22rem;
	}
}
@media (min-width: 1921px) {
	.conversations-preview-text {
		inline-size: 26rem;
	}
}
@media (min-width: 2561px) {
	.conversations-preview-text {
		inline-size: 36rem;
	}
}

.single-line-truncate {
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.vote-arrow {
	font-size: 2rem;
}

@media (min-width: 1921px) {
	.vote-arrow {
		font-size: 2.3rem;
	}
}

.view-count {
	font-size: 1.5rem;
}

@media (min-width: 1921px) {
	.view-count {
		font-size: 2rem;
	}
}

.not-found-404 {
	font-size: 9rem;
}

@media (min-width: 1921px) {
	.not-found-404 {
		font-size: 15rem;
	}
}

.variable-font-size {
	font-size: 0.8rem;
}

@media (min-width: 1024px) {
	.variable-font-size {
		font-size: 0.9rem;
	}
}

@media (min-width: 1921px) {
	.variable-font-size {
		font-size: 1.2rem;
	}
}

@media (min-width: 2100px) {
	.variable-font-size {
		font-size: 1.4rem;
	}
}
@media (min-width: 2561px) {
	.variable-font-size {
		font-size: 1.5rem;
	}
}

.tooltip:before {
	font-size: 1.3rem;
	background-color: #0c0e12;
	padding: 0.6rem;
}

.challenge-fade {
	--mask: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0, rgba(0, 0, 0, 1) 40%, rgba(0, 0, 0, 0) 95%, rgba(0, 0, 0, 0) 0) 100% 50% / 100% 100% repeat-x;

	font: 2em/1.6em Arial;
	-webkit-mask: var(--mask);
	mask: var(--mask);
}

.challengeDesc {
	color: white;
	display: flex;
	flex-direction: column;
	gap: 2rem;
	align-items: center;
	justify-content: center;

	& ul {
		font-size: 2rem;

		& li {
			padding: 2rem;
			background-color: #2a303c;
			margin-bottom: 0.5rem;
			border-radius: 0.5rem;
		}
	}

	& p {
		font-size: 2rem;
	}
}

.textShadow {
	text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
}
