// Change the default image for daisyUI cards
figure {
	width: 100%;
	aspect-ratio: 16/9;
	overflow: hidden;

	img {
		width: 100%;
		object-fit: fill;
	}
}

.card {
	transition: all ease-in-out 0.1s;
}
