@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

* {
	margin: 0;
	padding: 0;
	box-sizing: inherit;
}
html {
	font-size: 10px;
	box-sizing: border-box;
}

body {
	font-family: 'Open Sans', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

:root {
	--toastify-color-success: #36d399 !important;
	--toastify-color-error: #f87272 !important;
	--toastify-color-dark: #0c0e12 !important;
}

#root {
	@import './imports';
	min-height: 100vh;
}

.planet {
	background-repeat: no-repeat;
	background-position: bottom;
	background-position-y: 70vh;
	background-attachment: fixed;
}

.gradient {
	z-index: -100;
}

.pixel-font {
	font-family: 'Press Start 2P', cursive;
}

.unselectable {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
