.bg-base-900 {
	background-color: #0c0e12;
}
.bg-base-800 {
	background-color: rgb(22, 25, 32);
}

.bg-base-400 {
	background-color: #1d2129;
}

.bg-base-500 {
	background-color: #171b21;
}

.bg-base-600 {
	background-color: #101217;
}

.badge-probe {
	border-color: transparent;
	--tw-bg-opacity: 1;
	background-color: #30e653;
	--tw-text-opacity: 1;
	color: #101217;
}

.badge-spaceplane {
	border-color: transparent;
	--tw-bg-opacity: 1;
	background-color: #e6a75e;
	--tw-text-opacity: 1;
	color: #101217;
}

// .buildCardHover:hover {
// 	background-color: #171b21;
// }
