.scrollbar {
	&::-webkit-scrollbar-thumb {
		border-radius: 1rem;
		background-color: #a6adbb;
	}

	&::-webkit-scrollbar-track {
		background-color: #111318;
	}

	&::-webkit-scrollbar {
		height: 1rem;
		width: 0.5rem;
	}
}
